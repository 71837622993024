import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { getUserConsultationHistory } from '../apis'
import { AppBar, Box, Button, CircularProgress, Typography } from '@mui/material'
import Images from 'assets'
import { format } from 'date-fns'
import classes from './HistoryList.module.less'
import classnames from 'classnames'
import { useAppSelector } from 'app/hooks'
import { selectTranscription } from 'app/transcriptSlice'
import Appbar from '../h5Components/appbar/Appbar'
type HistoryItem = {
  ConsultationId: string
  CreatedDate: number
  Title: string
}
const formatDate = (createdDate: number) => {
  return format(createdDate, 'M-d-yyyy') + ' at ' + format(createdDate, 'h:mm a')
}
const HistoryList: React.FC<{ isMobile?: boolean }> = ({ isMobile }) => {
  const navigate = useNavigate()
  const consultation = useAppSelector(selectTranscription)
  const { id } = useParams<Record<string, string>>()
  const [loading, setLoading] = useState<boolean>(true)
  const [consultations, setConsultations] = useState<HistoryItem[]>([])
  const loader = useRef(null)
  const boxRef = useRef(null)
  const pagination = useRef({ skip: 0, take: 20, totalPage: 1 })
  const getData = useCallback(async () => {
    const { skip, take, totalPage } = pagination.current
    if (skip > totalPage) return
    getUserConsultationHistory<{ Consultations: HistoryItem[]; Total: number }>({ skip: skip * take, take })
      .then((res) => {
        setConsultations((pre) => [...pre, ...(res.Consultations || [])])
        setLoading(false)
        pagination.current.totalPage = Math.floor(res.Total / take)
        pagination.current.skip = skip + 1
      })
      .catch((res) => {})
  }, [])
  useEffect(() => {
    if (!consultation) return
    setConsultations((prevState) => {
      return (prevState || []).map((item) => {
        if (item.ConsultationId === consultation.pcId) {
          return Object.assign({}, item, consultation)
        }
        return item
      })
    })
  }, [consultation])

  useEffect(() => {
    getData()
  }, [getData])

  useEffect(() => {
    if (loading) return
    const options = {
      root: boxRef.current,
      rootMargin: isMobile ? '0px 0px 0px 0px' : '0px 0px 300px 0px',
      threshold: 0,
    }

    const observer = new IntersectionObserver(handleObserver, options)
    if (loader.current) {
      observer.observe(loader.current)
    }

    function handleObserver(entities: any[]) {
      const target = entities[0]
      if (target.isIntersecting) {
        getData()
      }
    }

    return () => observer.disconnect()
  }, [loading])
  if (loading) {
    return (
      <div className={classes.layout}>
        <CircularProgress sx={{ marginLeft: '45%', alignSelf: 'center' }} size={60} />
      </div>
    )
  }
  if (consultations.length === 0) {
    return (
      <div className={classes.layout}>
        <Typography className={classes.noData}>No Data</Typography>
      </div>
    )
  }
  return (
    <div className={isMobile ? classes.layoutMobile : classes.layout}>
      {isMobile && <Appbar title="Consultation History" />}
      <Box className={isMobile ? classes.leftMobile : classes.left} ref={boxRef}>
        {consultations.map((item) => {
          return (
            <Button
              key={item.ConsultationId}
              className={classnames(classes.item, {
                [classes.active]: id === item.ConsultationId,
                [classes.itemMobile]: isMobile,
              })}
              onClick={() => {
                navigate(`/history/${item.ConsultationId}`, {
                  replace: !isMobile,
                })
              }}
            >
              <Typography className={classnames(classes.title, { [classes.titleMobile]: isMobile })}>
                {item.Title}
              </Typography>
              <Box className={classes.timeContainer}>
                <img src={Images.time} alt="logo" />
                <Typography className={classnames(classes.time, { [classes.titleTime]: isMobile })}>
                  {formatDate(item.CreatedDate)}
                </Typography>
              </Box>
            </Button>
          )
        })}
        <div ref={loader} />
      </Box>
      {!isMobile && <Outlet />}
    </div>
  )
}

export default HistoryList
