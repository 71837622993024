import axiosService from './axiosService'
import Config from 'constants/Config'

console.log('process.env.NODE_ENV', process.env.NODE_ENV)
if (process.env.NODE_ENV === 'production') {
  axiosService.setBaseUrl(Config.baseUrl)
} else {
  axiosService.setBaseUrl('')
}

const handleGetRequest = <T>(url: string, data?: any) => {
  return axiosService.get<T>(`/pcs/${url}`, data)
}

const handlePostRequest = <T>(url: string, data: any) => {
  return axiosService.post<T>(`/pcs/${url}`, data)
}
export const saveRoundAssigner = <T>(data?: any) => handlePostRequest<T>('Round/SaveRoundAssigner', data)
export const visitorLogin = <T>(data?: any) => handlePostRequest<T>('User/VisitorLogin', data)
export const createConsultation = <T>(data?: any) => handlePostRequest<T>('Consultation/CreateConsultation', data)
export const uploadFileBase64 = <T>(data?: any) => handlePostRequest<T>('Consultation/UploadFileBase64', data)
export const register = <T>(data?: any) => handlePostRequest<T>('User/Register', data)
export const loginByPassword = <T>(data?: any) => handlePostRequest<T>('User/LoginByPassword', data)
export const updateConsultation = <T>(data?: any) => handlePostRequest<T>('Consultation/UpdateConsultation', data)
export const logout = <T>(data?: any) => handlePostRequest<T>('User/Logout', data)
export const getUserConsultationHistory = <T>(data?: any) =>
  handleGetRequest<T>('Consultation/GetUserConsultationHistory', data)
export const requestResetAuthCode = <T>(data?: any) => handlePostRequest<T>('User/RequestResetAuthCode', data)
export const verifyActionToken = <T>(data?: any) => handlePostRequest<T>('User/VerifyActionToken', data)
export const resetPassword = <T>(data?: any) => handlePostRequest<T>('User/ResetPassword', data)
export const getDeepgramKey = <T>(data?: any) => handleGetRequest<T>('Consultation/GetDeepgramKey', data)
export const getConsultationsSummary = <T>(data?: any) =>
  handleGetRequest<T>('Consultation/GetConsultationsSummary', data)
export const getUsersConsultationSummary = <T>(data?: any) =>
  handleGetRequest<T>('Consultation/GetUsersConsultationSummary', data)
export const updateUserProfile = <T>(data?: any) => handlePostRequest<T>('Profile/UpdateUserProfile', data)
export const generateSmartNote = <T>(data?: any) => handlePostRequest<T>('Consultation/GenerateSmartNote', data)
export const getNoteTemplates = <T>(data?: any) => handleGetRequest<T>('User/GetNoteTemplates', data)
export const getConsultationDetail = <T>(data?: any) => handleGetRequest<T>('Consultation/GetConsultationDetail', data)
export const getConsultationList = <T>(data?: any) => handleGetRequest<T>('Consultation/GetConsultationList', data)
export const submitFeedback = <T>(data?: any) => handlePostRequest<T>('Feedback/SubmitFeedback', data)
export const addCheckInLog = <T>(data?: any) => handlePostRequest<T>('User/AddCheckInLog', data)
export const createConsultationFormMobile = <T>(data?: any) =>
  handlePostRequest<T>('Consultation/CreateConsultationFormMobile', data)
export const generateOtpSecret = <T>(data?: any) => handlePostRequest<T>('Profile/GenerateOtpSecret', data)
export const enableMFAVerify = <T>(data?: any) => handlePostRequest<T>('Profile/EnableMFAVerify', data)
export const verifyToken = <T>(data?: any) => handlePostRequest<T>('User/VerifyToken', data)
export const startConsultation = <T>(data?: any) => handlePostRequest<T>('Consultation/StartConsultation', data)
export const submitConsultation = <T>(data?: any) => handlePostRequest<T>('Consultation/SubmitConsultation', data)
export const submitConsultationFeedback = <T>(data?: any) =>
  handlePostRequest<T>('Feedback/SubmitConsultationFeedback', data)
export const getAllConsultations = <T>(data?: any) => handleGetRequest<T>('Consultation/GetAllConsultations', data)
export const submitExportTask = <T>(data?: any) => handlePostRequest<T>('Consultation/SubmitExportTask', data)
export const getExportList = <T>(data?: any) => handleGetRequest<T>('Consultation/GetExportList', data)
export const downloadExportZip = <T>(data?: any) => handlePostRequest<T>('Consultation/DownloadExportZip', data)
export const getExportDetail = <T>(data?: any) => handleGetRequest<T>('Consultation/GetExportDetail', data)
export const acceptedAgreement = <T>(data?: any) => handlePostRequest<T>('Profile/AcceptedAgreement', data)
export const submitPatientConsent = <T>(data?: any) => handlePostRequest<T>('Consultation/SubmitPatientConsent', data)
