import { AppBar as MAppBar, Button, IconButton, Toolbar, Typography } from '@mui/material'
import React from 'react'
import Images from '../../assets'
import classes from './Appbar.module.less'
interface Props {
  showMenu?: boolean
  showClose?: boolean
  title?: string
  onClose?: () => void
  onMenu?: () => void
}
const Appbar: React.FC<Props> = ({ showMenu, showClose, title, onClose, onMenu }) => {
  return (
    <MAppBar position="static" className={classes.appbar}>
      <Toolbar className={classes.toolbar}>
        <IconButton style={{ opacity: showMenu ? 1 : 0 }} disabled={!showMenu} onClick={onMenu}>
          <img src={Images.menu} />
        </IconButton>
        <Typography className={classes.title}>{title}</Typography>
        <IconButton style={{ opacity: showClose ? 1 : 0 }} disabled={!showClose} onClick={onClose}>
          <img src={Images.close} />
        </IconButton>
      </Toolbar>
    </MAppBar>
  )
}

export default Appbar
